






import DocsGuidesMd from '@/docs/apidocs_guides.md';
import MarkdownItVue from 'markdown-it-vue';
import 'markdown-it-vue/dist/markdown-it-vue.css';

export default {
  components: {
    MarkdownItVue
  },
  data () {
    return {
      content: DocsGuidesMd
    }
  }
}
